<template>
  <div>
    <div
      class="modal-overlaybg"
      :class="{ open: isOpen }"
    ></div>
    <div
      id="help_modal"
      class="notify-cnt modal_pageview slideup"
      :class="{ modelslideup: isOpen }"
    >
      <div-wrapper class="model-header" @handleDivClick="closeModal">
        <span class="text-link">{{ $t("_close") }}</span>
      </div-wrapper>
      <div class="modal-midbox">
        <div class="text-box">
          <h2>{{ $t("_help") }}</h2>
          <div class="cnt-viewport">
            <p>{{ text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DivWrapper from '../form/DivWrapper.vue';
export default {
  components: { DivWrapper },
  props: ["text"],
  data() {
    return {
      isOpen: true
    };
  },
  methods: {
    closeModal() {
      this.isOpen = !this.isOpen;
      this.$emit("closeModal");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_modal-box.scss";
</style>
